/* eslint-disable react/style-prop-object */
function App() {
  return (


    <div class="flex items-center justify-center h-screen bg-gray-200">
      <div class="container">
        <div class="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
          <div class="text-center">
            <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              Nefin Yılmaz <span class="text-indigo-600">Prefabrik</span>
            </h2>
            <h3 class='text-xl md:text-3xl mt-10'>YAKINDA</h3>
            <p class="text-md md:text-xl mt-10"><a class="hover:underline" href="http://www.nyprefabrik.com">NY Prefabrik</a> </p>
          </div>
          <div class="flex flex-wrap mt-10 justify-center">
            <div class="m-3">
              <a href="https://www.facebook.com/NY-Prefabrik-101478475926549" title="Facebookda NY Prefabrik  "
                class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-blue-600 hover:border-blue-600 hover:bg-blue-600 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                <span class="mx-auto">Facebook</span>
              </a>
            </div>
            <div class="m-3">
              <a href="https://www.instagram.com/nyprefabrik/" title="Instagramda NY Prefabrik"
                class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-pink-600 hover:border-pink-600 hover:bg-pink-600 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                <span class="mx-auto">Instagram</span>
              </a>
            </div>
            <div class="m-3">
              <a href="https://twitter.com/NYPrefabrik" title="Twitterda NY Prefabrik"
                class="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                <span class="mx-auto">Twitter</span>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
